.page-footer {
    .top-footer {
        margin-bottom: 40px;

        .container {
            .top-footer__inner {
                .top-footer__left {
                    img {
                        width: 253px;
                    }
                }

                .arivic-top-footer__right {
                    ul {
                        display: flex;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            font-size: 35px;
                            font-weight: 800;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
