.page-header {
    .header-middle-bar {
        .image-wrapper {
            display: flex;

            img {
                display: flex;
                margin: 0 10px;
                align-self: center;
            }

            span {
                color: white;
            }
        }
    }
}
